import Vue from 'vue'
import VueRouter from 'vue-router'
import UserSteps from '../views/UserSteps.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'UserSteps',
    component: UserSteps
  },
  {
    path: '/results',
    name: 'Simulation',
    component: () => import('../views/Simulation.vue')
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () => import('../views/Documentation.vue')
  },
  {
    path: '*',
    name: 'catchAll',
    component: UserSteps
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
