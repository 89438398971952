<template>
	<div class="step">
		<div class="step-form-wrapper">
			<div class="step-label">
				Estamos Quase lá! :)
			</div>
			<div class="input-wrapper">
				<input
					type="text"
					class="input"
					v-model="$v.userName.$model"
					placeholder="Nome"
				/>
				<template v-if="isDirty">
					<span
						v-show="$v.userName.$invalid"
						class="error"
					>
						Informe seu nome.
					</span>
				</template>
			</div>
			<div class="input-wrapper">
				<input
					type="text"
					inputmode="email"
					class="input"
					v-model="$v.email.$model"
					placeholder="Email"
				/>
				<template v-if="isDirty">
					<span
						v-show="$v.email.$invalid"
						class="error"
					>
						Informe um email válido.
					</span>
				</template>
			</div>
			<div class="input-wrapper">
				<input
					type="text"
					inputmode="numeric"
					class="input"
					v-model="$v.phone.$model"
					v-mask="['(##) ####-####', '(##) #####-####']"
					placeholder="(ddd) WhatsApp"
				/>
				<template v-if="isDirty">
					<span
						v-show="$v.phone.$invalid"
						class="error"
					>
						Informe um número válido.
					</span>
				</template>
			</div>
			<div class="input-wrapper">
				<label class="checkbox-label">
					<input
						type="checkbox"
						class="agree"
						@click="toggleAgree"
					>
					Concordo em enviar os dados desta simulação para a Concede Crédito Imobiliário
				</label>
			</div>
		</div>
		<div class="button-wrapper">
			<button
				class="button return-button"
				@click="returnStep"
			>
				Voltar
			</button>
			<button
				class="button continue-button"
				@click="submit"
				:disabled="!agree"
			>
				Avançar
			</button>
		</div>
	</div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
	data () {
		return {
			// form data
			userName: '',
			email: '',
			phone: '',
			agree: false,
			// validation
			isDirty: false,
		}
	},
	validations: {
		userName: { required, minLength: minLength(2) },
		email: { required, email },
		phone: { minLength: minLength(14) },
	},
	props: ['onNextStep', 'onPreviousStep'],
	methods: {
		submit () {
			if (this.validate()) {
				this.$store.commit('setUserData', {
					userName: this.userName,
					email: this.email,
					phone: this.phone
				});

				if (this.onNextStep) {
					this.onNextStep();
				}
			}
		},
		validate () {
			this.isDirty = true;

			this.$v.userName.$touch();
			this.$v.email.$touch();
			this.$v.phone.$touch();

			if (this.$v.$invalid == false) {
				this.isDirty = false;
				return true;
			} else {
				return false;
			}
		},
		returnStep () {
			if (this.onPreviousStep) {
				this.onPreviousStep();
			}
		},
		toggleAgree () {
			this.agree = !this.agree;
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/style/forms.scss";
@import "./../../assets/style/step.scss";

.step-label {
	height: auto !important;
	margin: 64px 0 32px;

	@media only screen and (max-width: 639px) {
		margin: 32px 0;
	}
}

.input-wrapper {
	margin: 1rem 0;

	.input {
		text-align: left;
	}

	.checkbox-label {
		display: flex;
		align-items: center;
		font-size: 0.625rem;
		color: $deep-blue;
		cursor: pointer;

		.agree {
			min-height: 24px;
			min-width: 24px;
			margin-right: 1rem;
		}
	}
}
</style>