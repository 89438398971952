<template>
	<div class="step">
		<div class="step-form-wrapper">
			<div class="step-icon">
				<img :src="require(`../../assets/img/icons/valor-ic.svg`)">
			</div>
			<div class="step-label">
				Qual valor do imóvel?
			</div>
			<div class="step-form">
				<div class="input-wrapper">
					<input
						type="text"
						inputmode="numeric"
						class="input"
						placeholder="00,00"
						v-model="$v.buildingCost.$model"
						v-money="realFormat"
					/>
					<template v-if="isDirty">
						<span
							v-show="$v.buildingCost.$invalid"
							class="error"
						>
							Informe um valor válido.
						</span>
					</template>
				</div>
			</div>
		</div>
		<div class="button-wrapper">
			<button
				class="button return-button"
				@click="returnStep"
			>
				Voltar
			</button>
			<button
				class="button continue-button"
				@click="submit"
			>
				Avançar
			</button>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { notZeroValidation } from '../../assets/js/customValidations';
import real from '@/assets/js/v-money-real';

export default {
	data () {
		return {
			// form data
			buildingCost: '',
			// validation
			isDirty: false,
			realFormat: '',
		}
	},
	validations: {
		buildingCost: {
			required,
			notZeroValidation
		}
	},
	props: ['onNextStep', 'onPreviousStep'],
	mounted () {
		this.realFormat = real;

		const filters = this.$store.getters.getFilters;

		this.buildingCost = filters.buildingCost;
	},
	methods: {
		submit () {
			if (this.validate()) {
				const filters = this.$store.getters.getFilters;

				filters.buildingCost = this.buildingCost;

				this.$store.commit('setFilters', filters);

				if (this.onNextStep) {
					this.onNextStep();
				}
			}
		},
		validate () {
			this.isDirty = true;

			this.$v.buildingCost.$touch();

			if (this.$v.$invalid == false) {
				this.isDirty = false;
				return true;
			} else {
				return false;
			}
		},
		returnStep () {
			if (this.onPreviousStep) {
				this.onPreviousStep();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/style/forms.scss";
@import "./../../assets/style/step.scss";
</style>