export const unmaskMoney = (val) => {
  let unmaskedValue = val.substring(3);
  unmaskedValue = unmaskedValue.replace(/\./g, '');
  unmaskedValue = unmaskedValue.replace(/,/g, '.');

  return parseFloat(unmaskedValue).toFixed(2);
}

export const maskMoneyBR = (val) => {
  return parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 });
}

export const unmaskDate = (val) => { return val.replace(/\//g, '-').split('-').reverse().join('-') }

export const maskDateBR = (val) => { return val.replace(/-/g, '/').split('/').reverse().join('/') }