<template>
	<div class="step">
		<div class="step-form-wrapper">
			<div class="step-icon">
				<img :src="require(`../../assets/img/icons/data-ic.svg`)">
			</div>
			<div class="step-label">Qual sua data de nascimento?</div>
			<div class="step-form">
				<div class="input-wrapper">
					<input
						type="text"
						inputmode="numeric"
						class="input"
						v-mask="'##/##/####'"
						v-model="birthDate"
						placeholder="dd/mm/aaaa"
					/>
					<template v-if="isDirty">
						<span
							v-show="$v.birthDate.$invalid"
							class="error"
						>Informe uma data válida.</span>
					</template>
					<div class="input-description">
						Se for financiar com seu cônjuge, coloque a data de nascimento do mais velho.
					</div>
				</div>
			</div>
		</div>
		<div class="button-wrapper">
			<button
				class="button return-button"
				@click="returnStep"
			>
				Voltar
			</button>
			<button
				class="button continue-button"
				@click="submit"
			>
				Avançar
			</button>
		</div>
	</div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';
import { dateValidation } from '../../assets/js/customValidations';

export default {
	data () {
		return {
			// form data
			birthDate: '',
			// validation
			isDirty: false,
		}
	},
	validations: {
		birthDate: {
			required,
			minLength: minLength(10),
			dateValidation
		}
	},
	props: ['onNextStep', 'onPreviousStep'],
	mounted () {
		const filters = this.$store.getters.getFilters;

		this.birthDate = filters.birthDate;
	},
	methods: {
		submit () {
			if (this.validate()) {
				const filters = this.$store.getters.getFilters;

				filters.birthDate = this.birthDate;

				this.$store.commit('setFilters', filters);

				if (this.onNextStep) {
					this.onNextStep();
				}
			}
		},
		validate () {
			this.isDirty = true;

			this.$v.birthDate.$touch();

			if (this.$v.$invalid == false) {
				this.isDirty = false;
				return true;
			} else {
				return false;
			}
		},
		returnStep () {
			if (this.onPreviousStep) {
				this.onPreviousStep();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/style/forms.scss";
@import "./../../assets/style/step.scss";
</style>