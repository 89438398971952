import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    filters: {
      buildingCost: '',
      buildingEntryCost: '',
      birthDate: '',
      installments: ''
    },
    userData: {
      userName: '',
      email: '',
      phone: ''
    },
    partnership: {
      partnerID: '',
      partnerEmail: ''
    }
  },
  mutations: {
    setPartnership (state, partnership) {
      state.partnership = partnership;
    },
    setFilters (state, filters) {
      state.filters = filters;
    },
    setUserData (state, userData) {
      state.userData = userData;
    },
    setSimulations (state, simulations) {
      state.simulations = simulations;
    }
  },
  getters: {
    getPartnership (state) {
      return state.partnership;
    },
    getFilters (state) {
      return state.filters;
    },
    getUserData (state) {
      return state.userData;
    },
    getSimulations (state) {
      return state.simulations;
    }
  },
  actions: {
  },
  modules: {
  }
})
