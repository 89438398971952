<template>
	<div class="step">
		<div class="step-form-wrapper">
			<div class="step-icon">
				<img :src="require(`../../assets/img/icons/home-ic.svg`)">
			</div>
			<div class="step-label">
				Simule aqui e veja a comparação entre os bancos na hora
			</div>
			<button
				class="button"
				@click="handleClickStart"
			>
				Quero comprar um imóvel
			</button>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		handleClickStart () {
			if (this.onStart) {
				this.onStart();
			}
		}
	},
	props: ['onStart']
}
</script>

<style lang="scss" scoped>
@import "./../../assets/style/step.scss";
.step {
	.step-label {
		@media only screen and (min-width: 641px) {
			height: 175px !important;
		}
	}

	.button {
		margin-bottom: 2rem;

		@media only screen and (min-width: 641px) {
			margin: 0;
		}
	}
}
</style>