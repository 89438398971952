<template>
	<div class="user-steps">
		<simulator-header
			:step="step"
			:maxStep="maxStep"
		/>
		<div class="steps-wrapper">
			<user-steps-start
				v-if="step == 0"
				:onStart="goToNextStep"
			/>
			<user-steps-first-step
				v-else-if="step == 1"
				:onNextStep="goToNextStep"
				:onPreviousStep="goToPreviousStep"
			/>
			<user-steps-second-step
				v-if="step == 2"
				:onNextStep="goToNextStep"
				:onPreviousStep="goToPreviousStep"
			/>
			<user-steps-third-step
				v-if="step == 3"
				:onNextStep="goToNextStep"
				:onPreviousStep="goToPreviousStep"
			/>
			<div
				v-if="step == 4"
				class="redirecting"
			>
				Redirecionando para a simulação...
			</div>
		</div>
	</div>
</template>

<script>
import SimulatorHeader from '../components/General/SimulatorHeader.vue';
import UserStepsStart from '../components/UserSteps/UserStepsStart.vue';
import UserStepsFirstStep from '../components/UserSteps/UserStepsFirstStep.vue';
import UserStepsSecondStep from '../components/UserSteps/UserStepsSecondStep.vue';
import UserStepsThirdStep from '../components/UserSteps/UserStepsThirdStep.vue';
import { maskDateBR, maskMoneyBR } from '../assets/js/mask-helper';

export default {
	components: { SimulatorHeader, UserStepsStart, UserStepsFirstStep, UserStepsSecondStep, UserStepsThirdStep },
	data () {
		return {
			step: 0,
			minStep: 0,
			maxStep: 4
		}
	},
	mounted () {
		this.getDataFromQuery();
	},
	methods: {
		getDataFromQuery () {
			let { partnership, email, value, entryValue, birthDate, months } = this.$route.query;

			if (partnership) {
				const floatingBuildingCostString = `${value.substring(0, value.length - 2)}.${value.substring(value.length - 2)}`;
				const maskedBuildingCost = maskMoneyBR(parseFloat(floatingBuildingCostString));
				let maskedBuildingEntryCost = '';

				if (entryValue) {
					const floatingBuildingEntryCostString = `${entryValue.substring(0, entryValue.length - 2)}.${entryValue.substring(entryValue.length - 2)}`;
					maskedBuildingEntryCost = maskMoneyBR(parseFloat(floatingBuildingEntryCostString));
				}

				birthDate = maskDateBR(birthDate);

				this.$store.commit('setFilters', {
					buildingCost: maskedBuildingCost,
					buildingEntryCost: maskedBuildingEntryCost,
					birthDate: birthDate,
					months: months,
				});

				this.$store.commit('setPartnership', {
					partnerID: partnership,
					partnerEmail: email
				});
			}
		},
		goToNextStep () {
			if (this.step < this.maxStep) {
				this.step++;
			}

			if (this.step == this.maxStep) {
				this.completeSteps();
			}
		},
		goToPreviousStep () {
			if (this.step > this.minStep) {
				this.step--;
			}
		},
		completeSteps () {
			// this.sendEmail();
			setTimeout(this.continueToSimulation, 750);
		},
		continueToSimulation () {
			this.$router.push('/results');
		}
	},
}
</script>

<style lang="scss" scoped>
.user-steps {
	.steps-wrapper {
		min-height: calc(100vh - #{$simulator-header-height});
		display: flex;
		justify-content: center;
		width: 100%;

		.redirecting {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 1.5rem;
			color: $deep-blue;
			text-align: center;
			width: 70%;
			line-height: 2rem;

			@media only screen and (min-width: 1000px) {
				justify-content: flex-start;
				padding-top: 8rem;
			}
		}
	}
}
</style>